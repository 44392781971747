import React from "react"
import Css from "./CareerBikeSection.module.css"


const CareerBikeSection = props => {

  let data = props.content["bike-section"]
  let bgColor = (props.bgColor)?props.bgColor:"#F5F6F7"

  return (
    
    <div className={Css.BikeSection} style={{backgroundColor:bgColor}} >
      
        <div className={Css.Title}>{data["title"]}</div>
        <img className={Css.Picture} src={require('../../images/' + data['image'])} alt="Bike" />
        <img className={Css.Picture2} src={require('../../images/' + data['image'])} alt="Bike" />
    
    </div>
  )
}

export default CareerBikeSection