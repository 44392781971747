import React, { useState } from "react"
import Css from "./CareerPositionSection.module.css"

const CareerPositionSection = props => {
  let data = props.content["position-section"]
  let bgColor = props.bgColor ? props.bgColor : "#FFFFFF"

  let positions = []
  let positionsMobile = []

  const [showItemState, setShowItemState] = useState([])

  if (data["positions"]) {
    positions = data["positions"].map((item, idx) => {
      return (
        <li key={idx}>
          <span className={Css.Position}>{item["position"]}</span>
          <span className={Css.Location}>{item["location"]}</span>
        </li>
      )
    })

    positionsMobile = data["positions"].map((item, idx) => {
      return (
        <li key={idx}>
          <span
            onClick={evt => openAccordion(idx)}
            className={Css.Position}
            role="button"
            aria-hidden
          >
            {item["position"]}
          </span>
          <span
            className={
              showItemState[idx] ? Css.LocationOpened : Css.LocationClosed
            }
          >
            {item["location"]}
          </span>
        </li>
      )
    })
  }

  let openAccordion = idx => {
    if (showItemState[idx] === undefined) {
      showItemState[idx] = false
    }

    showItemState[idx] = !showItemState[idx]

    setShowItemState([...showItemState])
  }

  return (
    <div>
      <div className={Css.PositionSection} style={{ backgroundColor: bgColor }}>
        <div className={Css.Title}>{data["title"]}</div>
        <div className={Css.Subtitle}>{data["subtitle"]}</div>
        <ul className={Css.PositionsList}>{positions}</ul>
        <ul className={Css.PositionsListMobile}>{positionsMobile}</ul>
      </div>
      <div className={Css.InfoEmail}>
        <a href={`mailto:careers@airrostudio.com`}>careers@airrostudio.com</a>
      </div>
    </div>
  )
}

export default CareerPositionSection
